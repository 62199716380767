import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play domaindle" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the domain name of the day in 6 tries.
        You can only use domain names that are registered.
        After each guess, the color of the tiles will change to show how close your guess was to the domain name of the day.
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="W"
          status="correct"
        />
        <Cell value="A" />
        <Cell value="V" />
        <Cell value="E" />
        <Cell value="S" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter W is in the domain name of the day and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="L"
          status="present"
        />
        <Cell value="O" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is in the domain name of the day but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="V" />
        <Cell value="A" />
        <Cell value="G" />
        <Cell isRevealing={true} isCompleted={true} value="U" status="absent" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter U is not in the domain name of the day in any spot.
      </p>
      <p/>
      <br/>

      <h1 className="text-xl dark:text-gray-300">Looking for an extra challenge?</h1>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Switch on "Hard Mode" so you can only use domain names that are DNSSEC signed.
      </p>
      <br/>

      {/*<h1 className="text-xl">Sharing your score</h1>*/}
      {/*<p className="text-sm text-gray-500 dark:text-gray-300">*/}
      {/*  Sharing your score currently only works when playing on <br/>*/}
      {/*  <a target="_blank" rel="noreferrer"*/}
      {/*    href="https://www.domaindle.be/">https://www.domaindle.be/</a>*/}
      {/*</p>*/}
    </BaseModal>
  )
}
